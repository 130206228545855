import React from 'react';
import About from './about' ; 
import Experience  from './experience'; 
import Projects from './projects';

function MainBodyPage(){
    return(
        <>
        <About />
        <Experience />
        <Projects />
        </>
    );

}

export default MainBodyPage; 